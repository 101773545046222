.container {
  margin-top: var(--space-size-14);
  display: flex;
  justify-content: space-between;
}

.backButton,
.nextButton {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  color: var(--body-text);
  font-family: var(--sans-serif-header);
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 300;
}
