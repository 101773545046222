.container {
  display: flex;
  border-radius: 40px;
  max-width: var(--space-size-2-half);
  max-height: var(--space-size-4-half);
  background-color: var(--pastel-green);
  aspect-ratio: 16/9;
}

.image,
.content {
  max-width: 50%;
}

.image > img {
  width: 100%;
  height: 100%;
  border-radius: 40px 0 0 40px;
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5% 0 4% 6%;
  border-radius: 0 40px 40px 0;
}

.text > h2,
.text > p {
  width: 100%;
  max-width: var(--space-size-6);
  color: white;
  font-family: var(--sans-serif-header);
}

.text > h2 {
  font-weight: 400;
  padding-bottom: 5%;
  font-size: clamp(1.4rem, 2.5vw, 1.6rem);
}

.text > p {
  font-size: clamp(0.9rem, 3vw, 1rem);
  font-weight: 200;
}

.button button {
  width: var(--space-size-button);
  background-color: white;
  border-radius: 40px;
  border: none;
  color: var(--body-text);
  font-size: clamp(1.1rem, 3vw, 1.3rem);
  font-weight: 500;
  padding: 5% 0;
  margin-top: 6%;
  cursor: pointer;
}

@media only screen and (min-width: 700px) and (max-width: 1199px) {
  /* column */
  .container {
    height: 100%;
    min-width: var(--space-size-3);
    max-height: var(--space-size-4-half);
    max-width: none;
  }
}

@media only screen and (max-width: 699px) {
  /* tall */
  .container {
    flex-direction: column;
    border-radius: 57px;
    aspect-ratio: 0;
    height: 100%;
    max-height: none;
    max-width: var(--space-size-5-half);
  }

  .content {
    padding: 8% 10%;
    max-width: none;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  .image,
  .content {
    width: 100%;
    max-width: none;
  }

  .button {
    text-align: center;
    align-self: center;
  }

  .image > img {
    border-radius: 55px 55px 0 0;
  }

  .button button {
    margin-top: 6%;
  }
}
