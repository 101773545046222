.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dark-grey);
}

.content {
  max-width: var(--space-size-layout);
  margin: 0 auto;
  width: 100%;
  padding: 8% 6%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 8%;
  font-family: var(--sans-serif);
  color: var(--footer-text);
  line-height: 160%;
  font-weight: 300;
  font-size: clamp(1rem, 2.2vw, 1.25rem);
}

.online {
  min-width: 100%;
  margin-top: -2%;
}

.logoCol {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.logo {
  font-family: var(--logo);
  font-size: clamp(3rem, 5vw, 4rem);
}

.email {
  padding: max(var(--space-size-14), 1.5%) 0;
}

address {
  font-style: normal;
}

.social {
  display: flex;
  gap: max(var(--space-size-14), 2%);
  font-size: clamp(1.8rem, 4.5vw, 3rem);
}

@media only screen and (max-width: 950px) {
  /* mobile */
  .content {
    height: 100%;
    padding: 3% 6%;
    gap: 15%;
  }

  .column {
    width: 35%;
    padding: 2% 0;
  }

  .online {
    margin-top: 0;
    min-width: 40%;
  }

  .logoCol {
    width: 100%;
  }

  .logo {
    margin: var(--space-size-13) 0;
  }
}

@media only screen and (max-width: 375px) {
  .column {
    width: auto;
    padding: 2% 0;
  }

  .logoCol {
    width: 100%;
  }
}
