.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-size-13);
}

.imageContainer > img {
  width: 100%;
  max-width: 280px;
  height: auto;
  border-radius: 15%;
}

.container > span {
  text-align: center;
}

.imageContainer {
  position: relative;
}

.xmark {
  position: absolute;
  z-index: 1;
  left: 85%;
  top: -5%;
  background-color: white;
  color: var(--light-pink);
  height: 12%;
  width: 12%;
  padding: 2px;
  border: 2px solid var(--light-pink);
  border-radius: 50%;
}
