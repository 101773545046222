/* note .container is outermost element if needed */

.content {
  max-width: var(--space-size-layout);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5%;
  padding: 0 6%;
  height: 45vh;
}

.content > p {
  max-width: var(--space-size-2);
  width: 100%;
  color: var(--body-text);
  font-weight: 300;
}

.search {
  display: flex;
  justify-content: flex-end;
  min-width: var(--space-size-5);
  max-width: 100%;
  width: 100%;
  border: 3px solid var(--pastel-green-light);
  border-radius: 40px;
  padding: var(--space-size-13);
  padding: 1.5% 2%;
  background-color: white;
}

.search > input {
  width: 100%;
  outline: none;
  border: none;
  color: var(--body-text);
  font-family: var(--sans-serif-header);
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-weight: 300;
  padding: 0 2%;
  background-color: white;
}

.search > button {
  border: none;
  background: none;
}

.hide {
  display: none;
}

.magnifyingGlass {
  font-size: clamp(1.4rem, 3.5vw, 2rem);
  color: var(--pastel-green-light);
}

.close {
  margin-right: 1%;
  font-size: var(--font-size-6);
  color: var(--pastel-green-light);
}

@media only screen and (max-width: 767px) {
  .content > p {
    display: none;
  }

  .content {
    height: 30vh;
  }
}

/* phones */
@media only screen and (max-width: 479px) {
  .content {
    max-height: var(--space-size-7);
  }
}
