.container {
  background-color: var(--light-grey);
}

.content {
  margin: 0 auto;
  max-width: var(--space-size-layout);
  display: flex;
}

.welcomeImage {
  width: 50%;
  max-width: 100%;
  height: 82vh;
  object-fit: cover;
}

.welcomeMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: max(5%, var(--space-size-13));
  padding: 0 4%;
  width: 100%;
}

.button {
  margin-top: 1%;
  text-decoration: none;
  text-align: center;
}

.welcomeMessage > h1,
.welcomeMessage > p,
.welcomeMessage > .button {
  color: var(--body-text);
  font-family: var(--sans-serif-header);
}

.welcomeMessage > h1 {
  width: 100%;
  line-height: 150%;
  font-weight: 400;
}

.welcomeMessage > p {
  max-width: var(--space-size-4);
  width: 100%;
  font-weight: 300;
}

.welcomeMessage > .button {
  align-self: flex-start;
  background-color: var(--dark-pink);
  border-radius: min(25px, 2vw);
  border: none;
  color: white;
  font-weight: 400;
  padding: 2% 5%;
}

@media only screen and (max-width: 1199px) {
  .welcomeImage {
    height: auto;
    object-fit: contain;
  }

  .welcomeMessage {
    height: auto;
    padding-top: 8%;
    padding-bottom: 8%;
  }
}

@media only screen and (max-width: 479px) {
  .content {
    flex-direction: column;
  }

  .welcomeImage {
    max-height: 50vh;
    width: 100%;
    object-fit: cover;
  }

  .welcomeMessage {
    height: 100%;
    gap: var(--space-size-13);
  }

  .welcomeMessage > h1 {
    font-size: clamp(1.6rem, 3.5vw, 2.8rem);
  }
}

@media only screen and (max-width: 585px) {
  .welcomeMessage > .button {
    border-radius: 15px;
  }
}
