.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-size-13);
}

.imageContainer > img {
  width: 100%;
  height: auto;
  border-radius: 15%;
  min-width: var(--thumb-min-size);
}

.container > span {
  text-align: center;
}
