.container {
  padding: 6% 4%;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formContainer input {
  display: block;
  margin-bottom: 5%;
  border: 2px solid var(--pastel-green-light);
  border-radius: min(20px, 2vw);
  padding: 3%;
  font-family: var(--sans-serif-header);
  font-size: clamp(0.8rem, 2vw, 1.25rem);
  font-weight: 300;
  max-width: var(--space-size-4);
  width: 95%;
}

.formButtons {
  width: 85%;
  display: flex;
  justify-content: center;
  gap: 2%;
  margin-top: 3%;
}

.demoLoginBtn,
.loginBtn {
  border: 2px solid var(--pastel-green-light);
  border-radius: min(20px, 2vw);
  padding: 3%;
  font-family: var(--sans-serif-header);
  font-size: clamp(0.8rem, 2vw, 1.25rem);
  font-weight: 300;
  max-width: var(--space-size-6);
  cursor: pointer;
  width: 100%;
}

.container > h1 {
  text-align: center;
  margin-bottom: 5%;
}

@media only screen and (max-width: 585px) {
  .formContainer input,
  .demoLoginBtn,
  .loginBtn {
    border-radius: 15px;
  }
}
