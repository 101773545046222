.container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6%;
}

.formContainer {
  display: inline-flex;
  flex-direction: column;
  margin-top: 3%;
  width: auto;
}

.formContainer input {
  display: block;
  margin-bottom: 5%;
  border: 2px solid var(--pastel-green-light);
  border-radius: min(20px, 2vw);
  padding: 3%;
  font-family: var(--sans-serif-header);
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-weight: 300;
  min-height: var(--space-size-9);
  flex-grow: 1;
}

.registerBtn {
  align-self: flex-start;
  width: 100%;
}

.registerBtn > button {
  border: 2px solid var(--pastel-green-light);
  border-radius: min(20px, 2vw);
  padding: 2% 10%;
  font-family: var(--sans-serif-header);
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-weight: 300;
  cursor: pointer;
  margin-top: 2%;
}

.container > h1 {
  align-self: flex-start;
  font-size: clamp(1.6rem, 3.5vw, 2.8rem);
  font-weight: 400;
}

.errors {
  color: red;
  margin: 2%;
  list-style-type: disc;
  list-style-position: inside;
}

@media only screen and (max-width: 400px) {
  /* .container {
    padding: 4% 3%;
  } */

  .formContainer input {
    width: var(--space-size-5);
  }
}

@media only screen and (max-width: 585px) {
  .formContainer input,
  .registerBtn > button {
    border-radius: 15px;
  }
}

@media only screen and (min-width: 409px) {
  .formContainer input {
    min-width: var(--space-size-4-half);
  }
}
