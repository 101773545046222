/* bring in normalize.css styles */
@import-normalize;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  overflow-x: hidden;
}

#root {
  position: relative;
}

html {
  font-size: 16px;
  color: var(--body-text);
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-family: var(--sans-serif-header);
  font-size: clamp(1.2rem, 3.5vw, 2.8rem);
}

h2,
a {
  font-family: var(--sans-serif-header);
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 300;
}

p,
span {
  font-family: var(--sans-serif-header);
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-weight: 300;
}

main {
  margin: 0 auto;
}

ul {
  list-style-type: none;
}

:root {
  /* colours */
  --pastel-green: hsl(159, 30%, 43%);
  --pastel-green-light: hsl(159, 30%, 70%);
  --dark-pink: hsl(338, 46%, 48%);
  --light-pink: hsl(338, 46%, 65%);
  --light-grey: hsl(0, 0%, 97%);
  --dark-grey: hsl(0, 0%, 88%);
  --body-text: hsl(0, 0%, 17%);
  --footer-text: hsl(0, 0%, 38%);

  /* font sizes */
  --font-size-1: 72px;
  --font-size-2: 60px;
  --font-size-3: 48px;
  --font-size-heading: 42px;
  --font-size-4: 36px;
  --font-size-5: 30px;
  --font-size-6: 24px;
  --font-size-7: 20px;
  --font-size-8: 18px;
  --font-size-9: 16px;
  --font-size-10: 14px;
  --font-size-11: 12px;

  /* spacing sizes */
  --thumb-min-size: 200px;
  --space-size-layout: 1400px;
  --space-size-1: 768px;
  --space-size-2: 640px;
  --space-size-2-half: 576px;
  --space-size-3: 512px;
  --space-size-4: 384px;
  --space-size-4-half: 320px;
  --space-size-5: 256px;
  --space-size-5-half: 224px;
  --space-size-6: 192px;
  --space-size-button: 168px;
  --space-size-7: 128px;
  --space-size-8: 96px;
  --space-size-9: 64px;
  --space-size-10: 48px;
  --space-size-11: 32px;
  --space-size-12: 24px;
  --space-size-13: 16px;
  --space-size-14: 12px;
  --space-size-15: 8px;
  --space-size-16: 4px;

  /* breakpoints */
  --mobile-only: 599px;

  /* fonts */
  --logo: "Tangerine", cursive;
  --sans-serif-header: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --serif: "EB Garamond", Georgia, "Times New Roman", Times, serif;
}
