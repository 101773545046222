.container {
  height: 100%;
  flex-grow: 1;
}

.formContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5%;
  margin-top: 5%;
}

.formContainer input {
  display: block;
  margin-bottom: 5%;
  border: 2px solid var(--pastel-green-light);
  border-radius: min(20px, 2vw);
  padding: 3%;
  font-family: var(--sans-serif-header);
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-weight: 300;
  min-height: var(--space-size-9);
  width: 100%;
}

.errors {
  padding-top: 1%;
  color: red;
  font-family: var(--sans-serif-header);
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-weight: 300;
}

@media only screen and (max-width: 585px) {
  .formContainer input {
    border-radius: 15px;
  }
}

@media only screen and (min-width: 409px) {
  .formContainer input {
    min-width: var(--space-size-4-half);
  }
}
