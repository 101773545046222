.thumbsContainer {
  flex-grow: 1;
  padding: 5% 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8%;
}

.thumbnail {
  width: 25%;
  min-width: var(--thumb-min-size);
  margin: var(--space-size-15);
}

.errors {
  padding-top: 1%;
  color: red;
  font-family: var(--sans-serif-header);
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-weight: 300;
}
