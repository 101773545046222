.container {
  background-color: var(--dark-pink);
}

.content {
  max-width: var(--space-size-layout);
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 8% 6%;
  color: white;
}

.content > h1 {
  color: white;
  padding: 0 0 5% 0;
}

.thumbnailContainer {
  display: flex;
  gap: max(4%, var(--space-size-11));
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: auto;
}

/* allow overflow for smaller screens */
@media only screen and (max-width: 1299px) {
  .thumbnailContainer {
    overflow-x: scroll;
    padding: 0 6%;
    padding-bottom: var(--space-size-12);
  }

  /* when overflow, allow thumbnails container to go to edge of screen
  move padding to heading only.. */
  .content {
    padding: 8% 0;
  }

  .content > h1 {
    color: white;
    padding: 1% 0 5% 6%;
    font-size: clamp(1.8rem, 3.5vw, 2.8rem);
  }

  .thumbnail {
    flex: none;
  }
}
