.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: var(--light-grey);
  padding: 4% 8%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.closed {
  display: none;
}

.closeIcon {
  align-self: flex-end;
  font-size: clamp(2rem, 4vw, 3.5rem);
  color: var(--pastel-green-light);
  cursor: pointer;
}

.menuLinks {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2%;
}

.menuItem {
  display: block;
}

.menuItem > a {
  color: var(--pastel-green);
  font-weight: 400;
  font-family: var(--sans-serif-header);
  font-size: clamp(2rem, 3.5vw, 2.8rem);
}

/* apply link styles to other menu items not links currently */
.menuItem {
  color: var(--pastel-green);
  font-weight: 400;
  font-family: var(--sans-serif-header);
  font-size: clamp(2rem, 3.5vw, 2.8rem);
}

.logo {
  font-family: var(--logo);
  font-size: clamp(2rem, 5vw, 3rem);
}
