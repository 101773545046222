#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modalBackground {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modalContent {
  position: absolute;
  background-color: white;
  border-radius: 40px;
  width: 50%;
  min-width: var(--space-size-5);
  max-width: var(--space-size-3);
}
