.thumbsContainer {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
  margin-left: -5%;
  height: 100%;
  gap: max(8px, 2%);
}

.thumbnail {
  width: 25%;
  min-width: var(--thumb-min-size);
  margin-left: 5%;
  margin-bottom: 5%;
}

.errors {
  padding-top: 1%;
  color: red;
  font-family: var(--sans-serif-header);
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-weight: 300;
}
