.listSelected {
  background-color: var(--pastel-green-light);
}

.container {
  width: 30%;
  min-width: var(--space-size-6);
  min-height: 170px;
  color: var(--body-text);
  font-family: var(--sans-serif-header);
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-weight: 300;
}

.container li {
  border: 2px solid var(--pastel-green-light);
  border-top: none;
  border-bottom: 1px solid var(--pastel-green-light);
  padding: 8%;
}

.container li:first-of-type {
  border-top: 2px solid var(--pastel-green-light);
  border-top-left-radius: min(20px, 2vw);
  border-top-right-radius: min(20px, 2vw);
}

.container li:last-of-type {
  border-bottom-left-radius: min(20px, 2vw);
  border-bottom-right-radius: min(20px, 2vw);
  border-bottom: 2px solid var(--pastel-green-light);
}

@media only screen and (min-width: 340px) {
  .container {
    width: var(--space-size-4-half);
  }
}

@media only screen and (max-width: 585px) {
  .container li:first-of-type {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .container li:last-of-type {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}
