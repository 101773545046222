.container {
  flex-grow: 1;
  padding: 6% 0;
  gap: max(25px, 8%);
  display: flex;
  flex-wrap: wrap;
}

.thumbnail {
  min-width: 180px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: max(10px, 2%);
}

.content > h1 {
  font-weight: 500;
}

.content > h1,
.content > p {
  margin-bottom: 3%;
}

.price {
  font-weight: 500;
  font-family: var(--sans-serif-header);
  font-size: clamp(1.5rem, 3.5vw, 2.5rem);
}

.content > button {
  display: inline-block;
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-family: var(--sans-serif-header);
  background-color: var(--dark-pink);
  border-radius: min(25px, 2vw);
  border: none;
  color: white;
  font-weight: 400;
  padding: 2% 5%;
  max-width: 150px;
  cursor: pointer;
}

@media only screen and (max-width: 585px) {
  .content > button {
    border-radius: 15px;
  }
}
