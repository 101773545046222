header {
  background-color: var(--pastel-green);
}

.container {
  max-width: var(--space-size-layout);
  margin: 0 auto;
  padding: 0 6%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 18vh;
  max-height: var(--space-size-6);
}

.menu,
.icons,
.logo {
  color: var(--light-grey);
}

.menu,
.icons {
  width: 15%;
  font-size: clamp(1.2rem, 5.5vw, 2.5rem);
}

.menu {
  cursor: pointer;
}

.icons {
  display: flex;
  gap: 25%;
  justify-content: flex-end;
}

.logo {
  font-family: var(--logo);
  font-size: clamp(2rem, 10vw, 4rem);
}

/* set smaller max height when not trying to fill screen with Welcome
on smaller screen sizes */
@media only screen and (max-width: 1199px) {
  .container {
    max-height: var(--space-size-7);
  }
}
