/* commented out while fixing styles as element selectors applied globally */

.container > h1,
.container > h2 {
  color: var(--body-text);
  font-family: var(--sans-serif-header);
}

.container > h1 {
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 300;
}

.container > h2 {
  margin-top: 2%;
  font-size: clamp(1.6rem, 3.5vw, 2.8rem);
  font-weight: 400;
}
