.userDropdown {
  position: relative;
  cursor: pointer;
}

.userMenu {
  position: absolute;
  color: var(--body-text);
  min-width: var(--space-size-6);
  margin-top: var(--space-size-15);
  text-align: center;
  border: 2px solid var(--pastel-green-light);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: white;
}

.menuItem {
  display: block;
  font-family: var(--sans-serif-header);
  font-size: clamp(0.8rem, 2vw, 1.2rem);
  color: var(--body-text);
  font-weight: 300;
  padding: 4% 0;
  border-bottom: 1px solid var(--pastel-green-light);
  cursor: pointer;
}

.menuItem:last-of-type {
  border-bottom: none;
}

@media only screen and (max-width: 1200px) {
  .userMenu {
    right: -200%;
  }
}

@media only screen and (max-width: 900px) {
  .userMenu {
    min-width: var(--space-size-7);
  }
}
