.menuContainer {
  flex-grow: 1;
  padding: 8% 1%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10%;
  height: 100%;
}

.menuItem {
  min-height: calc(3 * 50px);
}

.errors {
  padding-top: 1%;
  color: red;
  font-family: var(--sans-serif-header);
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-weight: 300;
}
