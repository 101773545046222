/* note .container is outermost element if needed */

.content {
  max-width: var(--space-size-layout);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  padding: 8% 6%;
}

.content > h1 {
  margin-bottom: 2%;
}

.content > p {
  width: 90%;
  max-width: var(--space-size-2-half);
  margin-bottom: 5%;
}

.thumbnailContainer {
  display: flex;
  justify-content: space-between;
  gap: 5%;
}

.thumbnail {
  width: 50%;
}

@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .thumbnailContainer {
    flex-direction: column;
    align-items: center;
    gap: var(--space-size-10);
  }

  .thumbnail {
    width: auto;
    max-width: var(--space-size-2-half);
  }

  .content > h1 {
    font-size: clamp(1.8rem, 3.5vw, 2.8rem);
  }
}

@media only screen and (max-width: 699px) {
  /* tall */
  .thumbnailContainer {
    gap: 8%;
    overflow-x: scroll;
    padding: var(--space-size-12);
    width: 100%;
  }

  .thumbnailContainer > :first-child {
    margin-left: auto;
  }

  .thumbnailContainer > :last-child {
    margin-right: auto;
  }

  /* move padding to allow thumbnails to go to edge with internal padding */
  .content {
    padding: 10% 0;
  }

  .content > h1,
  .content > p {
    padding: 0 6%;
  }

  .thumbnail {
    flex: none;
    width: auto;
  }

  .thumbnail {
    max-width: var(--space-size-5);
  }

  .content > h1 {
    font-size: clamp(1.8rem, 3.5vw, 2.8rem);
  }
}
